import React from "react";
import styles from "./ButtonWithIcon.module.css";

const ButtonWithIcon = ({ icon, text, onClick }) => {
  return (
    <button className={styles.button_with_icon} onClick={onClick}>
      <img src={icon} alt="Icon" className={styles.button_icon} />
      <span className={styles.button_text} >{text}</span>
    </button>
  );
};

export default ButtonWithIcon;
