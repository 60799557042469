import React from "react";
import { useState } from "react";
import styles from "./Main.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonWithIcon from "../../common/buttonWithIcon/ButtonWithIcon";

const Main = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("Español");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language) => {
    let langCode = language === "Español" ? "es" : "en"; // Determinar el código de idioma
    setSelectedLanguage(language);
    i18n.changeLanguage(langCode); // Cambiar el idioma activo
    setIsOpen(false);
  };

  return (
    <div className={styles.main}>
      {/* Header */}
      <header className={styles.header}>
        <div>
          <a href="/" className={styles.logo}>
            <img src="../../img/logo.png" alt="Logo" />
            <span>{t("appName")}</span>
          </a>
          <div className={styles.selector}>
            <button className={styles.selector_button} onClick={toggleDropdown}>
              {selectedLanguage}
            </button>
            {isOpen && (
              <ul className={styles.dropdown_menu}>
                <li onClick={() => selectLanguage("Español")}>Español</li>
                <li onClick={() => selectLanguage("English")}>English</li>
              </ul>
            )}
          </div>
        </div>

        <div className={styles.header_buttons}>
          <button
            class="border_button"
            onClick={() => {
              navigate("/signUp");
            }}
          >
            {t("signUp")}
          </button>
          <button
            class="solid_button"
            onClick={() => {
              navigate("/signIn");
            }}
          >
            {t("signIn")}
          </button>
        </div>
      </header>

      <div>
        {/* Top section */}
        <section className={styles.top_section}>
          <article className={styles.top_article}>
            <h1>{t("header1")}</h1>
            <p>{t("description1")}</p>
            <div className={styles.app_links}>
              <ButtonWithIcon
                icon="../../img/ios_icon.png"
                text={t("iosApp")}
                onClick={() => {
                  window.open(
                    "https://apps.apple.com/us/app/onix/id6468330705"
                  );
                }}
              />
              <ButtonWithIcon
                icon="../../img/android_icon.png"
                text={t("androidApp")}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.siens.android.app.onix"
                  );
                }}
              />
            </div>
          </article>
        </section>

        {/* Top section */}
        <section className={styles.middle_section}>
          <article className={styles.middle_article}>
            <h1>{t("header2")}</h1>
            <p>{t("description2")}</p>
          </article>
        </section>

        {/* Last section */}
        <section className={styles.last_section}>
          <article className={styles.top_article}>
            <h1>{t("header3")}</h1>
            <p>{t("description3")}</p>
          </article>
        </section>
      </div>

      {/* Footer */}
      <footer className={styles.footer}>
        <div className={styles.footer_content}>
          <p>{t("contact")}</p>
          <p>{t("contactEmail")}</p>
        </div>
        <div className={styles.footer_links}>
          <a href="/terms">{t("terms")}</a>
          <a href="/policy">{t("policy")}</a>
          <p className={styles.org}>{t("companyName")} &copy;</p>
        </div>
      </footer>
    </div>
  );
};

export default Main;
